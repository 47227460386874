<template>
  <div>
    <r-page-modal
      :prompt-on-leave="false"
      prompt-message="Are You sure?"
      v-if="isModalHierarchyOpen"
      @hide="closeHierarchy"
      :is-modal="true"
      class="hierarchy-sidebar"
    >
      <template #page-content>
        <div class="sidebar-header-hierarchy columns is-multiline">
          <div class="column is-1">
            <b-icon
              icon="arrow-left"
              class="click"
              @click.native="closeHierarchy"
            ></b-icon>
          </div>
          <div class="column is-11">
            <h3 class="modal-title has-text-primary">Reporting To</h3>
          </div>
          <Vue2OrgTree
            :data="data"
            :props="props"
            :render-content="renderContent"
            :children="data.hierarchy"
            :collapsable="collapsable"
            :horizontal="horizontal"
            :labelWidth="labelWidth"
            @on-expand="onExpand"
          ></Vue2OrgTree>
        </div>
      </template>
    </r-page-modal>
  </div>
</template>
<script>
import ApiService from '@/services/common/api.service'
import 'vue2-org-tree/dist/style.css'
export default {
  props: ['id', 'isModalHierarchyOpen', 'closeHierarchy'],

  data() {
    return {
      data: {},
      props: {
        label: 'full_name',
        children: 'hierarchy',
        expand: 'expand',
      },
      horizontal: true,
      collapsable: true,
      labelWidth: 200,
      expandAll: true,

      // Tree Data Component
      treeData: {
        name: null,
        image_url: null,
        children: null,
      },
    }
  },

  async mounted() {
    await this.getHierarchy(this.id)
  },

  methods: {
    async getHierarchy(id) {
      let response = await ApiService.get(
        `/api/space-roketin/user/hierarchy/${id}`
      )

      this.data = response.data.data.hierarchy[0]
    },

    renderContent(h, data) {
      return [
        data.fullName,
        ' (',
        data && data.positionName ? data.positionName : '-',
        ')',
      ]
    },

    onExpand(e, data) {
      if ('expand' in data) {
        data.expand = !data.expand

        if (!data.expand && data.hierarchy) {
          this.collapse(data.hierarchy)
        }
      } else {
        this.$set(data, 'expand', true)
      }
    },
  },
}
</script>
