<template>
  <div v-if="people != null">
    <r-page-modal
      :prompt-on-leave="false"
      prompt-message="Are You sure?"
      v-if="isSideBarOpen"
      @hide="closeSidebar"
      :is-modal="true"
      class="people-sidebar"
    >
      <template #page-content>
        <div class="sidebar-header-section">
          <h3 class="modal-title has-text-primary">Personal Info</h3>
          <img
            :src="
              determineProfilePicture(people.profilePictureUrl, people.gender)
            "
            alt="Profile"
            class="profile-picture"
          />
          <h4 class="employees-name has-text-primary">
            {{ people.fullName }}
          </h4>
          <p class="employees-position has-text-primary">
            {{ people.positionName }}
          </p>
        </div>
        <div class="columns is-multiline data-section">
          <div class="column is-12 mb-22px">
            <div class="is-justify-content-flex-start">
              <b-tag
                type="is-success"
                class="mr-2 has-text-white has-text-weight-bold"
                size="is-medium"
              >
                {{ people.isActive ? 'Active' : 'Inactive' }}
              </b-tag>
              <b-tag
                type="is-danger"
                v-if="determineNewEmployee(people.joinedAt, true)"
                class="mr-2 has-text-weight-bold"
                size="is-medium"
              >
                New
              </b-tag>
              <b-tag
                type="is-info"
                v-if="formatBirthday(people.dateOfBirth, true)"
                class="mr-2 has-text-weight-bold"
                size="is-medium"
              >
                {{ formatBirthday(people.dateOfBirth, true) }}
              </b-tag>
            </div>
          </div>
          <div class="column is-4 mb-22px">
            <p class="data-title">Department</p>
          </div>
          <div class="column is-8 mb-22px">
            <p class="data-content">
              {{ people.departmentName ? people.departmentName : '-' }}
            </p>
          </div>

          <div class="column is-4 mb-22px">
            <p class="data-title">Location</p>
          </div>

          <div class="column is-8 mb-22px">
            <p class="data-content">
              {{ people.locationName ? people.locationName : '-' }}
            </p>
          </div>

          <div class="column is-4 mb-22px">
            <p class="data-title">Reporting to</p>
          </div>
          <div class="column is-8 mb-22px">
            <p class="data-content is-capitalize" v-if="people.reportingTo">
              {{ people.reportingTo.fullName }}
            </p>
            <b-button
              class="is-command"
              v-if="people.reportingTo"
              icon-left="account-group"
              @click.native="openHierarchy(people.id)"
            >
              See more hierarchy
            </b-button>
            <p class="data-content" v-else>-</p>
          </div>

          <div class="column is-4 mb-22px">
            <p class="data-title">Email</p>
          </div>
          <div class="column is-8 mb-22px">
            <p class="data-content">{{ people.workEmail }}</p>
          </div>

          <div class="column is-4 mb-22px" v-if="!isViewedAsTable">
            <p class="data-title">Birthday</p>
          </div>
          <div class="column is-8 mb-22px" v-if="!isViewedAsTable">
            <p class="data-content">{{ formatDate(people.dateOfBirth) }}</p>
          </div>

          <div class="column is-4 mb-22px" v-if="!isViewedAsTable">
            <p class="data-title">Birthday in</p>
          </div>
          <div class="column is-8 mb-22px" v-if="!isViewedAsTable">
            <p class="data-content">
              {{ formatBirthday(people.dateOfBirth, true) }}
            </p>
          </div>

          <div class="column is-4 mb-22px" v-if="!isViewedAsTable">
            <p class="data-title">Contract</p>
          </div>
          <div class="column is-8 mb-22px" v-if="!isViewedAsTable">
            <p class="data-content">{{ formatDate(people.endContractAt) }}</p>
          </div>

          <div class="column is-4 mb-22px" v-if="!isViewedAsTable">
            <p class="data-title">Contract end</p>
          </div>
          <div class="column is-8 mb-22px" v-if="!isViewedAsTable">
            <p class="data-content">
              {{ formatCountDown(people.endContractAt) }}
            </p>
          </div>

          <div class="column is-4 mb-22px" v-if="!isViewedAsTable">
            <p class="data-title">Probation</p>
          </div>
          <div class="column is-8 mb-22px" v-if="!isViewedAsTable">
            <p class="data-content">{{ formatDate(people.probationEndAt) }}</p>
          </div>

          <div class="column is-4 mb-22px" v-if="!isViewedAsTable">
            <p class="data-title">Probation end</p>
          </div>
          <div class="column is-8 mb-22px" v-if="!isViewedAsTable">
            <p class="data-content">
              {{ formatCountDown(people.probationEndAt) }}
            </p>
          </div>

          <div class="column is-4 mb-22px">
            <p class="data-title">About</p>
          </div>
          <div class="column is-8 mb-22px">
            <p class="data-content">
              {{ people.about ? people.about : '-' }}
            </p>
          </div>

          <!-- <div class="column is-12" v-if="people.startContractAt">
            <p class="fontsize-detail-people">
              {{ countJoinedAgo }}
            </p>
          </div> -->
        </div>
      </template>
    </r-page-modal>
    <RightBarContentHierarchy
      :id="people.id"
      :isModalHierarchyOpen="isModalHierarchyOpen"
      :closeHierarchy="closeHierarchyModal"
    ></RightBarContentHierarchy>
  </div>
</template>

<script>
import moment from 'moment'
import RightBarContentHierarchy from '../People/RightBarContentHierarchy'

export default {
  components: { RightBarContentHierarchy },
  props: [
    'people',
    'isSideBarOpen',
    'closeSidebar',
    'determineProfilePicture',
    'formatDate',
    'formatBirthday',
    'formatCountDown',
    'isViewedAsTable',
    'determineNewEmployee',
  ],
  data() {
    return {
      isModalHierarchyOpen: false,
      id: null,
    }
  },
  date() {
    return {
      isModalOpen: true,
    }
  },
  computed: {
    countJoinedAgo() {
      let peopleStartWorkDate = new Date(this.people.startContractAt)
      let today = moment().format('YYYY-MM-DD')
      let startWorkMomment = moment(
        peopleStartWorkDate.getFullYear() +
          '-' +
          (peopleStartWorkDate.getMonth() + 1) +
          '-' +
          peopleStartWorkDate.getDate()
      )
      let diff = startWorkMomment.diff(today, 'days')
      let absdif = -diff
      let year = Math.floor(absdif / 365)
      let month = Math.floor((absdif % 365) / 30)
      return `Joined ${year} years and ${month} months ago`
    },
  },

  methods: {
    openHierarchy(id) {
      this.isModalHierarchyOpen = true
      this.id = id
    },

    closeHierarchyModal() {
      this.isModalHierarchyOpen = false
      this.id = null
    },
  },
}
</script>
