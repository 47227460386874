<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page>
      <template #page-header>
        <h2 class="page-title">
          People
          <span class="people-subheader">({{ getTotal }} Employees)</span>
        </h2>
        <r-menu-tab :level="2"></r-menu-tab>
      </template>
      <template #action-bar>
        <div class="level relative">
          <div class="level-left"></div>

          <div class="level-right transition-all">
            <b-button
              icon-left="menu"
              class="people-btn-icon"
              @click="toggleView(true)"
              v-if="!isViewedAsTable"
            ></b-button>
            <!---  hide button to toggle view as card 
            (RISE-1081 https://astronauts.atlassian.net/browse/RISE-1081?atlOrigin=eyJpIjoiZWJlMzE4ZjVjMTkxNDA4YjljMDRjNGY4MzM1NDRlMjMiLCJwIjoiaiJ9)
             -->
            <!--            <b-button-->
            <!--              icon-left="card-bulleted-outline"-->
            <!--              class="people-btn-icon"-->
            <!--              @click="toggleView(false)"-->
            <!--              v-if="isViewedAsTable"-->
            <!--            ></b-button>-->
            <div class="relative">
              <b-button
                icon-left="filter-variant"
                class="btn-filter-people"
                @click="toggleFilter"
              >
                Filter
              </b-button>
            </div>

            <b-field class="search-container">
              <b-input
                expanded
                v-model="search"
                placeholder="Search"
                type="text"
                @keydown.native.enter="searchIconClick"
                icon-clickable
                icon-right="magnify"
                @icon-right-click="searchIconClick"
                icon-right-clickable
                class="search-page-people"
                :loading="isPeopleDataLoading"
              ></b-input>
            </b-field>
          </div>
        </div>
      </template>
      <template #page-content>
        <div class="filter-card-people" v-if="isFilterOpen">
          <h4 class="filter-title">Filter</h4>
          <div class="reset-filter" @click="resetFilter">Reset</div>

          <!-- department -->
          <p class="filter-subtitle">Department</p>
          <div class="filter-section-container">
            <div
              class="filter-department-item"
              :class="mapFilter('department', item.id)"
              v-for="(item, index) in departmentsList"
              :key="index"
              @click="filterDepartments(item.id)"
            >
              {{ item.departmentName }}
            </div>
          </div>

          <!-- status -->
          <p class="filter-subtitle">Status</p>
          <div class="filter-section-container">
            <div
              class="filter-department-item"
              :class="mapFilter('status', item.id)"
              v-for="(item, index) in statusList"
              :key="index"
              @click="filterStatus(item.id)"
            >
              {{ item.workingStatusName }}
            </div>
          </div>

          <!-- location -->
          <p class="filter-subtitle">Location</p>
          <div class="filter-section-container">
            <div
              class="filter-department-item"
              :class="mapFilterRemote(false, mainLocation.id)"
              @click="filterLocation(mainLocation && mainLocation.id)"
            >
              {{ mainLocation && mainLocation.city }}
            </div>
            <div
              class="filter-department-item"
              :class="mapFilterRemote(true)"
              @click="filterLocation('isRemote')"
            >
              Remote
            </div>
          </div>

          <!-- sort -->
          <p class="filter-subtitle" v-if="!isViewedAsTable">Sort</p>
          <div class="filter-section-container" v-if="!isViewedAsTable">
            <div
              class="filter-department-item"
              :class="mapFilterSort(true)"
              @click="filterCustomSort(true)"
            >
              A-Z
            </div>
            <div
              class="filter-department-item"
              :class="mapFilterSort(false)"
              @click="filterCustomSort(false)"
            >
              Z-A
            </div>
          </div>
        </div>

        <!-- Card View -->
        <div v-if="!isViewedAsTable">
          <div class="columns is-multiline mt-2">
            <div
              class="column is-one-fifth card-people"
              v-for="(item, index) in peopleData"
              :key="index"
              @click="selectEmployee(item)"
            >
              <div class="card-people-ribbon">{{ item.departmentName }}</div>
              <img
                :src="
                  determineProfilePicture(item.profilePictureUrl, item.gender)
                "
                alt="Profile"
                class="card-people-image"
              />
              <p class="card-people-name">
                {{ item.fullName }}
              </p>
              <p class="card-people-email">{{ item.workEmail }}</p>
              <div class="is-flex is-justify-content-center">
                <div class="card-people-department">
                  <span class="card-people-content">
                    {{ item.positionName }}
                  </span>
                  <span class="card-people-content ml-1">
                    {{ formatBirthday(item.dateOfBirth) }}
                  </span>
                  <b-icon
                    icon="party-popper"
                    class="birthday-icon"
                    v-if="isOnBirthday(item.dateOfBirth)"
                  ></b-icon>
                </div>
              </div>
              <p class="card-people-content">
                Status: {{ formatProbation(item.probationEndAt) }}
              </p>
            </div>
            <b-loading
              :is-full-page="isFullPage"
              v-model="isPeopleDataLoading"
              :can-cancel="true"
            ></b-loading>
            <section
              class="section column is-12 mt-6"
              v-if="
                peopleData && peopleData.length === 0 && !isPeopleDataLoading
              "
            >
              <div class="content has-text-grey has-text-centered">
                <p>No data found</p>
              </div>
            </section>
          </div>
        </div>

        <!-- Table View -->
        <div
          id="table-request-employment"
          class="table-timeoff-type"
          v-if="isViewedAsTable"
        >
          <b-table
            :data="peopleData"
            :per-page="perPage"
            :loading="isPeopleDataLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            class="employment-directory-table general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
            :selected.sync="selectedEmployee"
          >
            <b-table-column
              field="employments.id"
              label="Employee ID"
              v-slot="props"
              sortable
              width="8%"
            >
              <span class="is-capitalize">
                {{ props.row.employmentId }}
              </span>
            </b-table-column>

            <b-table-column
              field="users.first_name"
              label="Full Name"
              v-slot="props"
              sortable
              width="20%"
            >
              <div class="columns employment-table-name">
                <img
                  class="log-activity-profile-picture"
                  :src="
                    determineProfilePicture(
                      props.row.profilePictureUrl,
                      props.row.gender
                    )
                  "
                />
                <div
                  class="is-capitalize is-flex is-justify-content-flex-start is-align-items-center"
                >
                  <div class="mr-2">
                    {{ props.row.fullName }}
                  </div>
                  <b-tag
                    type="is-danger"
                    class="has-text-white has-text-weight-bold mr-4 is-rounded"
                    size="is-small"
                    v-if="determineNewEmployee(props.row.joinedAt)"
                  >
                    New
                  </b-tag>
                </div>
              </div>
            </b-table-column>

            <b-table-column
              field="positions.position_name"
              label="Position"
              v-slot="props"
              sortable
              width="10%"
            >
              <span class="is-capitalize">
                {{ props.row.positionName }}
              </span>
            </b-table-column>

            <b-table-column
              field="users.work_email"
              label="Work Email"
              v-slot="props"
              sortable
              width="14%"
            >
              <span>
                {{ props.row.workEmail }}
              </span>
            </b-table-column>

            <b-table-column
              field="users.working_statuses.working_status_name"
              label="Status"
              v-slot="props"
              sortable
              width="6%"
            >
              <span
                :class="`${determineFontColorByStatus(
                  props.row.workingStatus
                )}`"
              >
                {{ props.row.workingStatus }}
              </span>
            </b-table-column>

            <b-table-column
              field="employments.date_of_birth"
              label="Birthday"
              width="8%"
              v-slot="props"
              sortable
            >
              <b-tag
                v-if="formatBirthday(props.row.dateOfBirth, false)"
                class="has-text-white has-text-weight-bold"
                type="is-info"
              >
                {{ formatBirthday(props.row.dateOfBirth, false) }}
              </b-tag>
            </b-table-column>

            <b-table-column
              field="employments.probation_end_at"
              label="Probation Date"
              v-slot="props"
              sortable
              width="14%"
              v-if="currentUser.isAdmin"
            >
              <span>
                {{ formatDate(props.row.probationEndAt) }}
              </span>
            </b-table-column>

            <b-table-column
              field="employments.end_contract_at"
              label="Contract End Date"
              v-slot="props"
              sortable
              width="14%"
              v-if="currentUser.isAdmin"
            >
              <span>
                {{ formatDate(props.row.endContractAt) }}
              </span>
            </b-table-column>
            <!--            <template slot="empty">-->
            <!--              <section class="section">-->
            <!--                <div class="content has-text-grey has-text-centered">-->
            <!--                  <p>No data foundz</p>-->
            <!--                  <p>People data : {{peopleData}}</p>-->
            <!--                </div>-->
            <!--              </section>-->
            <!--            </template>-->
          </b-table>
        </div>

        <!-- Sidebar -->
        <RightBarContentPeopleDetail
          :people="selectedPeople"
          :isSideBarOpen="isSideBarOpen"
          :closeSidebar="closeSidebar"
          :determineProfilePicture="determineProfilePicture"
          :formatDate="formatDate"
          :formatBirthday="formatBirthday"
          :formatCountDown="formatCountDown"
          :isViewedAsTable="isViewedAsTable"
          :determine-new-employee="determineNewEmployee"
        />
      </template>
    </r-page>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import RightBarContentPeopleDetail from './People/RightBarContentPeopleDetail'

export default {
  name: 'People',
  components: { RightBarContentPeopleDetail },

  data() {
    return {
      search: null,
      startDate: '',
      endDate: '',
      isViewedAsTable: true,
      isSideBarOpen: false,
      isSearching: false,
      isFullPage: true,

      // filter
      isFilterOpen: false,
      departmentsList: null,
      statusList: null,
      mainLocation: null,
      departmentIds: [],
      statusIds: [],
      locationIds: [],
      isRemote: null,

      // table view
      stickyHeaders: true,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,
      defaultSortOrder: 'asc',
      sortField: 'employments.id',
      sortOrder: 'asc',
      isPeopleDataLoading: false,
      isFilterListLoading: false,
      isLoadMore: false,
      selectedEmployee: null,

      selectedPeople: null,
    }
  },
  async mounted() {
    await this.loadPeopleDataList()
    await this.loadFilterList()

    // mapping filter data
    this.departmentsList = this.filterData?.department
    this.statusList = this.filterData?.workingStatus
    this.mainLocation = this.filterData?.locations?.[0]
  },
  computed: {
    ...mapGetters({
      peopleData: 'user/getPeopleListData',
      filterData: 'user/getPeoplesFilter',
      currentUser: 'user/getUserInfo',
    }),
    getTotal() {
      return this.total
    },
  },
  methods: {
    ...mapActions({
      actionFetchPeopleList: 'user/fetchPeopleList',
      actionFetchPeoplesFilter: 'user/fetchPeoplesFilter',
    }),

    determineNewEmployee(date) {
      let momentCurrent = moment()
      let joinDate = moment(date)
      let diff = momentCurrent.diff(joinDate, 'days')
      // 1 month duration since join
      if (diff <= 31) {
        return true
      }
      return false
    },

    ...mapMutations({
      setPeopleList: 'user/setPeopleList',
    }),

    closeSidebar() {
      this.isSideBarOpen = false
      this.selectedPeople = null
    },

    formatProbation(date) {
      const today = moment(new Date())
      const end = moment(new Date(date))

      if (moment(today).isAfter(end)) {
        return '-'
      } else {
        return 'Probation'
      }
    },

    formatBirthday(date, isSidebar) {
      const today = moment()
      const year = moment().year()
      const birthMonth = moment(date).month()
      const birthDate = moment(date).date()
      const birthday = moment([year, birthMonth, birthDate])

      const diff = birthday.startOf('day').diff(today.startOf('day'), 'days')
      if (isSidebar) {
        if (diff === 0) {
          return `Happy Birthday!`
        } else if (diff === 1) {
          return `${diff} day to birthday`
        } else if (diff > 1 && diff <= 7) {
          return `${diff} days to birthday`
        } else {
          return null
        }
      } else {
        if (diff === 0) {
          return `Happy Birthday!`
        } else if (diff === 1) {
          return `${diff} day to birthday`
        } else if (diff > 1 && diff <= 7) {
          return `${diff} days to birthday`
        } else {
          return null
        }
      }
    },

    formatCountDown(date) {
      const today = moment(new Date())
      const target = moment(new Date(date))

      const diff = target.startOf('day').diff(today.startOf('day'), 'days')
      if (diff === 0) {
        return `Last day!`
      } else if (diff === 1) {
        return `in ${diff} day`
      } else if (diff > 1 && diff <= 90) {
        return `in ${diff} days`
      } else {
        return '-'
      }
    },

    isOnBirthday(date) {
      const today = moment(new Date()).format('YYYY-MM-DD')
      const year = moment().year()
      const birthMonth = moment(date).month()
      const birthDate = moment(date).date()
      const birthday = moment([year, birthMonth, birthDate]).format(
        'YYYY-MM-DD'
      )

      if (today === birthday) {
        return true
      } else {
        return false
      }
    },

    formatDate(str) {
      const d = new Date(str)
      return moment(d).format('DD MMMM YYYY')
    },

    determineFontColorByStatus(status) {
      if (status === 'online') {
        return 'status-online'
      } else {
        return 'status-offline'
      }
    },

    determineProfilePicture(picture, gender) {
      if (!picture) {
        if (gender === 'F') {
          // female
          return '/images/default-profile-picture-female.png'
        }
        // male
        return '/images/default-profile-picture-male.png'
      } else {
        return picture
      }
    },

    selectEmployee(user) {
      this.selectedPeople = user
      this.isSideBarOpen = true
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadPeopleDataList()
        this.isLoadMore = false
      }
    },

    async searchIconClick() {
      await this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setPeopleList([])
      await this.loadPeopleDataList()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    onPageChange(page) {
      this.page = page
      this.loadPeopleDataList()
    },

    toggleView(bool) {
      this.isViewedAsTable = bool
      this.search = null
      this.isFilterOpen = false
      this.closeSidebar()
      this.resetFilter()
      this.resetState()
    },

    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen
    },

    resetFilter() {
      this.departmentIds = []
      this.statusIds = []
      this.locationIds = []
      this.isRemote = null
      this.setPeopleList([])
      this.isFilterOpen = false
      this.loadPeopleDataList()
    },

    mapFilter(filter, id) {
      let source
      if (filter === 'department') {
        source = this.departmentIds
      } else if (filter === 'status') {
        source = this.statusIds
      }

      const index = source.indexOf(id)
      if (index >= 0) {
        return 'filter-active'
      }
    },

    mapFilterRemote(bool, id) {
      if (!bool) {
        const source = this.locationIds
        const index = source.indexOf(id)
        if (index >= 0) {
          return 'filter-active'
        }
      } else if (bool && this.isRemote) {
        return 'filter-active'
      }
    },

    mapFilterSort(isAscending) {
      if (
        this.sortField === 'users.first_name' &&
        isAscending &&
        this.sortOrder === 'asc'
      ) {
        return 'filter-active'
      } else if (
        this.sortField === 'users.first_name' &&
        !isAscending &&
        this.sortOrder === 'desc'
      ) {
        return 'filter-active'
      }
    },

    async filterDepartments(id) {
      const isAlreadyExist = this.departmentIds.indexOf(id)
      if (isAlreadyExist >= 0) {
        this.departmentIds.splice(isAlreadyExist, 1)
      } else {
        this.departmentIds.push(id)
      }
      this.resetState()
      await this.loadPeopleDataList(true)
    },

    async filterStatus(id) {
      const isAlreadyExist = this.statusIds.indexOf(id)
      if (isAlreadyExist >= 0) {
        this.statusIds.splice(isAlreadyExist, 1)
      } else {
        this.statusIds.push(id)
      }
      this.resetState()
      await this.loadPeopleDataList(true)
    },

    async filterLocation(id) {
      if (id && id !== 'isRemote') {
        const isAlreadyExist = this.locationIds.indexOf(id)
        if (isAlreadyExist >= 0) {
          this.locationIds.splice(isAlreadyExist, 1)
        } else {
          this.locationIds.push(id)
        }
      } else if (id === 'isRemote') {
        if (this.isRemote) {
          this.isRemote = null
        } else {
          this.isRemote = 1
        }
      }
      this.resetState()
      await this.loadPeopleDataList(true)
    },

    async filterCustomSort(isAscending) {
      if (isAscending) {
        this.sortOrder = 'asc'
      } else {
        this.sortOrder = 'desc'
      }
      this.sortField = 'users.first_name'

      this.resetState()
      await this.loadPeopleDataList(true)
    },

    async loadPeopleDataList(isFiltering) {
      this.isPeopleDataLoading = true

      try {
        const params = {
          perPage: this.perPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: this.search,
          departmentIds: this.departmentIds,
          statusIds: this.statusIds,
          locationIds: this.locationIds,
        }

        if (isFiltering) {
          params.isFiltering = true
          params.page = this.page
          params.isRemote = this.isRemote
        } else {
          params.page = ++this.page
          params.isRemote = null
        }

        const response = await this.actionFetchPeopleList(params)

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isPeopleDataLoading = false
    },

    async loadFilterList() {
      this.isFilterListLoading = true

      try {
        await this.actionFetchPeoplesFilter()
      } catch (err) {
        console.log(err)
      }
      this.isFilterListLoading = false
    },
  },

  watch: {
    selectedEmployee(row) {
      this.selectEmployee(row)
    },
  },
}
</script>
